import React from 'react'
import './Prices.scss'
import { graphql, useStaticQuery } from 'gatsby';

const Prices = ({ className = '' }) => {

  const data = useStaticQuery(graphql`
    query PricesQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "prices" } }) {
        frontmatter {
          categories {
            description
            price
          }
        }
      }
    }
  `);

  const { categories = [] } = data?.markdownRemark?.frontmatter || {}

  const categoryCount = categories.length

  return (
    <div className={`prices ${className} mb-4`}>
      <h1>Prices</h1>
      {categories.map((category, i) => (
        <div key={`category-${i}`}>
          <p>{category.description}</p>
          <div style={{ whiteSpace: 'pre-line' }}>
            {category.price}
          </div>
          {categoryCount === i + 1 ? null : (
            <hr className="dotted is-bota-purple"></hr>
          )}
        </div>
      ))}
    </div>
  )
}

export default Prices;
