import React from 'react'
import './ImageSlider.scss'

export default class ImageSlider extends React.Component {
  state = {
    currentIndex: 0,
    isLoading: true,
    galleryId: null,
    sliderId: Math.floor(Math.random()*90000).toString(),
    sliderImages: [],
    currentImage: null,
    interval: null
  }

  componentDidMount() {
    const { images = [] } = this.props

    const interval = setInterval(this.handleImageChange, 10000)

    this.setState({ interval })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  incrementImage = () => {
    const {
      currentIndex
    } = this.state
    const {
      images,
    } = this.props

    this.setState({
      currentIndex: currentIndex < (images.length - 1) ? currentIndex + 1 : 0
    })
  }

  handleImageChange = () => {
    if (this.props.images.length > 1) {
        this.setState({
          isLoading: true
        });
        setTimeout(this.incrementImage, 250)
    }
  }

  handleImageLoaded = () => {
    this.setState({
      isLoading: false
    })
  }

  renderSlider = () => {
    const { images = [] } = this.props
    const { currentIndex } = this.state

    const imageSrc = images[currentIndex]?.image?.childImageSharp?.fluid?.src

    return (
      <div className="wrapper">
        <div className="content-container">
        <div
          className="img-container"
          style={{backgroundImage: `url(${imageSrc})`}}
        />
          <img
            onLoad={this.handleImageLoaded}
            className=""
            src={imageSrc}

           />
        </div>
      </div>
    )
  }

  render() {
    return (
      <figure className="has-shadow">
          {
            this.props.images.length > 0 && this.renderSlider()
          }
      </figure>
    )
  }
}
