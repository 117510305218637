import React from 'react'
import BotaText from '../../components/BotaText'
import Button from '../../components/Button'
import ImageSlider from '../../components/ImageSlider'
import Prices from '../../components/Prices'
import Section from '../../components/Section'
import OpeningHours from '../../components/OpeningHours'
import './PricesAndMenus.scss'
import { graphql, useStaticQuery } from 'gatsby'
import DownloadableMenus from '../../components/DownloadableMenus'

const PricesAndMenus = ({ config = {}, ...rest }) => {
  const data = useStaticQuery(graphql`
    query PriceAndMenusQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          priceSlider {
            image {
              childImageSharp {
                fluid(maxWidth: 640, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ourMenuSlider {
            image {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const { priceSlider, ourMenuSlider } = data?.markdownRemark?.frontmatter || {}

  return (
    <div className="wood-background">
        <Section name="prices">
          <div className="container">
            <div className="columns is-multiline">
              <div className="column is-one-third details">

                <Prices />

                <div className="columns is-multiline" style={{ marginTop: '8px' }}>
                    
                  <DownloadableMenus />
                    <div className="column mt-1">
                        <Button
                          href="/book"
                          className="button is-danger is-block has-shadow"
                          text="Book Now"
                          icon="fa fa-caret-right"
                          internal={true}
                        />

                    </div>
                </div>


              </div>
              <div className="column is-two-thirds has-meats-background">
                  <ImageSlider images={priceSlider} />

                    <div className="columns">

                        <div className="column details location">

                          <h1>Where</h1>
                          <div>
                            <p>37 John William Street</p>
                            <p>Huddersfield HD1 1BL</p>
                            <a href="#contact-us"><i className="fa fa-map"></i> View Map</a>
                            <hr className="dotted is-bota-purple"></hr>
                            <a href="tel:+441484535440"><i className="fa fa-phone"></i> 01484 535 440</a>
                            <a href="mailto:info@botafogo.co.uk?subject=Website%20Enquiry"><i className="fa fa-envelope"></i> info@botafogo.co.uk</a>

                          </div>


                        </div>
                        <div className="column details opening-times">

                          <OpeningHours />

                        </div>
                    </div>
              </div>

              </div>
            </div>
        </Section>
        <Section name="menus">
          <span className="green-wrapper" />
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className="columns">
                  <div className="column is-hidden-touch">
                      <div className="menu-header">

                       <BotaText
                         size="2.75rem"
                         content="OUR MENUS"
                         colour="green"
                         width="380"
                       />
                      </div>
                    </div>
                  <div className="column">
                    <div className="menu-header">

                    </div>
                  </div>
                </div>
                <ImageSlider {...rest} images={ourMenuSlider} />
                  <div className="columns">
                    <div className="column">

                        <Button
                          href="/menus/mains"
                          className="button is-danger is-block has-shadow"
                          text="Mains"
                          internal
                        />
                        <Button
                          href="/menus/sides"
                          className="button is-danger is-block has-shadow"
                          text="Included sides"
                          internal
                        />


                      </div>
                      <div className="column">


                        <Button
                          href="/menus/drinks"
                          className="button is-danger is-block has-shadow"
                          text="Drinks"
                          internal
                        />
                        <Button
                          href="/menus/desserts"
                          className="button is-danger is-block has-shadow"
                          text="Desserts"
                          internal
                        />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </Section>
      </div>

  )

}

export default PricesAndMenus