import React from 'react'
import PropTypes from 'prop-types'
import './ImageTile.scss'

export default class ImageTile extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    image: PropTypes.object,
    handleShowModal: PropTypes.func,
    position: PropTypes.number
  }

  render() {
    const { image, handleShowModal, position } = this.props
    const imageSrc = image?.image?.childImageSharp?.fluid?.src

    return (
          <div>
            <figure className="gallery-tile">
              <div
                className="gallery-image"
                style={{backgroundImage: `url(${imageSrc})`, backgroundSize: 'cover' }}
                onClick={handleShowModal}
                data-position={position}
              />
            </figure>
          </div>
        )
  }
}
