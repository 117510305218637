import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Section from '../../components/Section'
import BotaText from '../../components/BotaText'
import ImageTile from '../../components/ImageTile'
import Modal from '../../components/Modal'
import './Gallery.scss'

export default class Gallery extends React.Component {

  state = {
    showModal: false,
    modalImage: {},
    position: 0
  }

  closeModal = () => {
    this.setState({
      showModal: false
    })
  }

  showLast = () => {
    const {
      position,
    } = this.state;
    const { items = [] } = this.props
    const newPosition = position == 0 ? 0 : position - 1;

    this.setState({
      position: newPosition,
      modalImage: items[newPosition]
    });
  }

  showNext = () => {
    const {
      position,
    } = this.state;

    const { items = [] } = this.props
    const newPosition = (position == items.length - 1) ?  position : position + 1

    this.setState({
      position: newPosition,
      modalImage: items[newPosition]
    });
  }

  showModal = (modalImage, position) => {
    this.setState({
      showModal: !this.state.showModal,
      modalImage,
      position,
    });
  }

  render() {
    return (
      <Section name="gallery">
        <div className="is-hidden-touch container is-fluid wood-background gallery-title">
          <div className="columns is-desktop">
            <div className="column">
              <div className="container">
                <div className="columns is-desktop">
                  <div className="column gallery-title-column">
                    <h1 className="title is-1 bota-title">
                      <span className="fa fa-instagram"></span>
                      <BotaText
                        size="2.5rem"
                        content="GALLERY"
                        colour="purple"
                        width="240"
                      />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero gallery">
          <div className="container">
            <div className="columns is-multiline is-mobile">
              <div className="column is-12 gallery-title">
                <h1>Nice to <i className="fa fa-instagram" /> <i className="fa fa-facebook" /> <i className="fa fa-tripadvisor" /> <i className="fa fa-twitter" /> you!</h1>
                <p>Check-in or Tag @BotafogoGrill #MeatFeast, and you may appear on our website!</p>
              </div>
              {
                this.props.items.map((item, i) => {
                  return (
                    <div className="column is-2-desktop is-4-tablet is-half-mobile" key={i}>
                      <ImageTile
                        image={item}
                        handleShowModal={() => this.showModal(item, i)}
                      />
                    </div>
                  )
                })
              }

            </div>
          </div>
          {
            this.state.showModal ? (
              <Modal
                item={this.state.modalImage}
                closeModal={this.closeModal}
                showNext={this.showNext}
                showLast={this.showLast}
                isActive
              />
          ) : null
          }
        </div>

      </Section>
    )
  }
}
