import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import Button from '../Button'
const DownloadableMenus = () => {
  const data = useStaticQuery(graphql`
    query DownloadableMenusQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "menu-downloads" } }) {
        frontmatter {
          foodMenu {
            publicURL
          }
          drinksMenu {
            publicURL
          }
        }
      }
    }
  `);

  const { foodMenu, drinksMenu } = data?.markdownRemark?.frontmatter || {};

  return (
    <>
      <div className="column is-6">
        <Button
          href={foodMenu?.publicURL}
          className="button is-large is-warning is-block has-shadow"
          text="Food Menu"
        />
      </div>
      <div className="column is-6">
          <Button
            href={drinksMenu?.publicURL}
            className="button is-small is-warning is-block has-shadow"
            text="Drinks Menu"
          />
      </div>
    </>
  )
};

export default DownloadableMenus