import React from 'react'
import PropTypes from 'prop-types'

import './Modal.scss'

export default class Modal extends React.Component {

  static propTypes = {
    isActive: PropTypes.bool,
    item: PropTypes.object.isRequired,
  }

  static defaultProps = {
    isActive: false
  }

  handleResize = (e) => {
    // console.('hello')
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.escapeKeyListener);
    window.addEventListener('keydown', this.navigateKeys);
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.escapeKeyListener);
    window.removeEventListener('keydown', this.navigateKeys);
  }

  escapeKeyListener = (e) => {
    const evt = e || window.event;
    let isEscape;
    if ("key" in evt) {
        isEscape = (evt.key == "Escape" || evt.key == "Esc");
    } else {
        isEscape = (evt.keyCode == 27);
    }
    if (isEscape) {
        //delete isEscape
        this.props.closeModal()
    }
  }

  navigateKeys = (e) => {
    const evt = e || window.event;
    let isEscape;
    if ("key" in evt) {
        if (evt.keyCode == 37) {
            this.props.showLast()
        }

        if (evt.keyCode == 39) {
            this.props.showNext()
        }
    }
  }

  handleSwipe = (e) => {
    if (e.offsetDirection == 2) {
      this.props.showNext()
    }

    if (e.offsetDirection == 4) {
      this.props.showLast()
    }
  }

  render() {
    const { item, isActive } = this.props
    const { altText, image } = item;
    const imageSrc = image?.childImageSharp?.fluid?.src

    return (

            <div className={isActive ? "modal is-active" : ""}>
              <div className="modal-background" />
              <div className="modal-content" data-test={this.props.direction}>
                <p className="image">
                  <img
                    src={imageSrc}
                    alt={altText} />
                </p>
                <p className="caption">{altText}</p>
              </div>
              <button onClick={this.props.closeModal}
                className="modal-close" style={{marginTop: "40px"}}/>
            </div>
        )
  }
}
